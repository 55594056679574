export default [
  {
    header: 'Dashboard',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'ActivityIcon',
      },
    ],
  },
]
